import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSession } from "store";

const withNoAuthentication = (Component) => {
  const ProtectedRoute = (props) => {
    const { isLoggedIn } = useSession();
    const router = useRouter();

    useEffect(() => {
      if (isLoggedIn) {
        const { redirect } = router.query;
        router.replace(redirect || "/");
      }
    }, [isLoggedIn]);
  
    if (!isLoggedIn) return <Component {...props} />;
    return null;
  };
  return ProtectedRoute;
};

export default withNoAuthentication;
