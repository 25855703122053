import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { createContext, useContext, useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

export const light = responsiveFontSizes(createMuiTheme({
  props: {
    MuiTooltip: {
      arrow: true,
      style: {
        pointerEvents: "all",
      },
    },
    MuiButton: {
      disableFocusRipple: true,
    },
    MuiDivider: {
      style: {
        marginTop: 16,
        marginBottom: 16,
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Rubik",
    },
    button: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#583FEE",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00aced",
      contrastText: "#fff",
    },
    text: {
      primary: "#252525",
      secondary: "#555555",
    },
    background: {
      default: "#f5f5f5",
      paper: "#fff",
    },
    common: {
      black: "#0F100B",
      white: "#FCFCFC",
    },
  },
}));

export const dark = responsiveFontSizes(createMuiTheme({
  props: {
    MuiTooltip: {
      arrow: true,
      style: {
        pointerEvents: "all",
      },
    },
    MuiButton: {
      disableFocusRipple: true,
    },
    MuiDivider: {
      style: {
        marginTop: 16,
        marginBottom: 16,
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Rubik",
    },
    button: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#836ff2",
      contrastText: "#fff",
    },
    background: {
      paper: "#333",
      default: "#222",
    },
    secondary: {
      main: "#00aced",
      contrastText: "#fff",
    },
    common: {
      black: "#0F100B",
      white: "#FCFCFC",
    },
  },
}));

export const ThemeSelectorContext = createContext({
  theme: "",
});

export const useThemeSelector = () => useContext(ThemeSelectorContext);

export const withTheme = (Component) => (props) => {
  const [t, setT] = useState("light");
  const toggleTheme = () => {
    const newTheme = t === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setT(newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setT(savedTheme);
    }
  }, []);
  const theme = t === "light" ? light : dark;
  
  return (
    <ThemeSelectorContext.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    </ThemeSelectorContext.Provider>
  );
};
