import { ToastContainer } from "react-nextjs-toast";

const withDialogs = (Component) => (props) => (
  <>
    <Component {...props} />
    <ToastContainer />
  </>
);

export default withDialogs;
