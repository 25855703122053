import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Header from "components/Header";

const useStyles = makeStyles({
  contentContainer: {
    flexGrow: 1,
    height: "100%",
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
  },
});

const AppLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" minHeight="110vh">
      <Header />
      <Box className={classes.contentContainer}>
        {children}
      </Box>
    </Box>
  );
};
  
export default AppLayout;
