import useSWR from "swr";
import { hit } from "api/hit";

export const lightFetcher = (key) => hit(JSON.parse(key)).then((res) => {
  if (res && res.error) throw new Error(res.error);
  return res;
});

export const heavyFetcher = (key) => hit(JSON.parse(key)).then((res) => {
  if (res && res.error) throw new Error(res.error);
  return res.data;
});

const useResource = (endpoint, { fetcher, options } = {}) => {
  const key = JSON.stringify(endpoint);
  const { data, error, mutate } = useSWR(key, fetcher || heavyFetcher, options);
  return {
    data, error, loading: !data && !error, mutate,
  };
};

export default useResource;
