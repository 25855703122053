import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Link from "next/link";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  logoBox: ({ color, scale }) => ({
    overflow: "hidden",
    cursor: "pointer",
    fill: color || (palette.type === "light" ? palette.primary.main : palette.action.active),
    display: "flex",
    justifyContent: "center",
    height: 100 * scale,
    width: 200 * scale,
    [breakpoints.down("sm")]: {
      height: 70 * scale,
      width: 140 * scale,
    },
  }),
}));

const LogoSVG = ({ complete, color, scale = 0.9, style, className, ...rest }) => {
  const classes = useStyles({ color, scale });
  return (
    <Box className={clsx(classes.logoBox, className)} {...rest}>
      {
        complete ? (
          <>
            <svg viewBox="0 0 528 470" xmlns="http://www.w3.org/2000/svg">
              <path d="M199.384 0.564668C79.9458 3.73638 1.16401 86.8468 0.208055 210.896C-0.410503 293.125 0.208061 375.766 0.939084 458.23C0.911232 458.778 0.911232 459.327 0.939084 459.875C1.30376 461.916 2.15591 463.828 3.4165 465.434C4.6771 467.039 6.30536 468.286 8.15036 469.06C9.99536 469.833 11.9974 470.107 13.9708 469.857C15.9443 469.606 17.8253 468.84 19.4396 467.628C58.8024 439.729 101.82 439.787 145.4 440.845C203.432 442.372 261.633 442.43 319.721 440.845C454.679 437.144 529.075 352.742 527.782 205.903C526.601 81.6194 443.433 0.97581 315.56 0.329722C276.816 0.0947804 238.072 -0.551303 199.384 0.564668ZM225.644 237.973C224.351 241.791 233.179 246.372 237.847 249.72C238.289 250.021 238.6 250.49 238.716 251.028C238.831 251.565 238.742 252.129 238.465 252.598C219.121 284.961 150.349 249.485 177.397 196.095L177.734 195.39L178.015 194.979C179.673 191.927 181.716 189.122 184.088 186.638C229.693 142.293 270.012 211.131 226.6 236.622C226.182 237.089 225.841 237.625 225.588 238.208L225.644 237.973ZM358.691 236.387C358.273 236.854 357.931 237.39 357.678 237.973V237.973C356.385 241.791 365.214 246.372 369.881 249.72C370.323 250.021 370.634 250.49 370.75 251.028C370.865 251.565 370.776 252.129 370.499 252.598C351.155 284.961 282.383 249.485 309.431 196.095L309.768 195.39V194.979C311.407 191.914 313.451 189.106 315.841 186.638C361.783 142.058 402.102 210.896 358.691 236.387Z" />
            </svg>
            <svg style={{ marginLeft: "5%", width: "110%" }} viewBox="0 0 1293 293" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.6479 285.539C11.8608 285.804 10.0394 285.631 8.32743 285.034C6.61545 284.437 5.05983 283.432 3.78357 282.099C2.5073 280.766 1.54535 279.141 0.973643 277.353C0.401936 275.564 0.236165 273.662 0.489449 271.795V83.8422C0.274365 81.9937 0.468658 80.1182 1.05739 78.3605C1.64612 76.6028 2.61352 75.0098 3.88499 73.7045C5.15645 72.3991 6.69804 71.4163 8.39075 70.8318C10.0835 70.2472 11.8821 70.0766 13.6479 70.3331H49.8055C51.6179 70.0375 53.4713 70.1849 55.22 70.7637C56.9686 71.3424 58.565 72.3368 59.8837 73.6686C61.2024 75.0004 62.2075 76.6335 62.82 78.4395C63.4325 80.2455 63.6359 82.1753 63.4139 84.0772V95.8242H64.2574C69.631 85.4407 77.7004 76.8513 87.5383 71.0434C97.3761 65.2354 108.583 62.4444 119.872 62.9912C123.419 62.9386 126.966 63.0758 130.5 63.4024C132.189 63.5197 133.837 64.0032 135.338 64.8218C136.839 65.6405 138.16 66.7766 139.218 68.1577C140.276 69.5388 141.047 71.1348 141.482 72.8441C141.918 74.5534 142.007 76.3386 141.746 78.0862V111.33C141.832 113.873 141.625 116.417 141.128 118.907C140.957 119.738 140.596 120.513 140.078 121.168C139.559 121.822 138.897 122.336 138.147 122.666C136.963 123.317 135.659 123.697 134.323 123.782H128.194C125.045 123.782 122.571 123.782 120.546 123.782C112.923 123.355 105.297 124.597 98.1604 127.43C91.0233 130.262 84.5319 134.621 79.1029 140.228C69.0184 151.153 63.995 166.542 64.0325 186.394V271.795C64.2759 273.665 64.1012 275.568 63.5222 277.356C62.9433 279.143 61.9758 280.766 60.6956 282.098C59.4154 283.429 57.8574 284.433 56.1436 285.03C54.4298 285.627 52.6069 285.801 50.8178 285.539H13.6479Z" />
              <path d="M269.287 292.647C255.156 293.13 241.086 290.515 227.981 284.971C214.877 279.427 203.03 271.078 193.204 260.46C173.41 239.002 163.495 211.592 163.457 178.231C163.42 144.869 173.279 117.283 193.035 95.4726C202.864 84.8088 214.735 76.4302 227.874 70.884C241.013 65.3378 255.125 62.7486 269.287 63.2856C281.475 62.8016 293.621 65.0459 304.91 69.8679C316.199 74.69 326.371 81.9782 334.741 91.2436H335.585V84.0779C335.332 82.2113 335.497 80.3088 336.069 78.5206C336.641 76.7324 337.603 75.1076 338.879 73.7746C340.155 72.4415 341.711 71.4367 343.423 70.8396C345.135 70.2424 346.956 70.0693 348.743 70.3338H385.294C387.082 70.0693 388.903 70.2424 390.615 70.8396C392.327 71.4367 393.882 72.4415 395.159 73.7746C396.435 75.1076 397.397 76.7324 397.969 78.5206C398.54 80.3088 398.706 82.2113 398.453 84.0779V272.031C398.706 273.898 398.54 275.8 397.969 277.588C397.397 279.376 396.435 281.001 395.159 282.334C393.882 283.667 392.327 284.672 390.615 285.269C388.903 285.866 387.082 286.04 385.294 285.775H348.743C346.956 286.04 345.135 285.866 343.423 285.269C341.711 284.672 340.155 283.667 338.879 282.334C337.603 281.001 336.641 279.376 336.069 277.588C335.497 275.8 335.332 273.898 335.585 272.031V264.63H334.741C326.342 273.861 316.167 281.13 304.886 285.959C293.605 290.787 281.472 293.067 269.287 292.647V292.647ZM280.533 240.725C287.821 240.965 295.068 239.508 301.75 236.461C308.431 233.413 314.38 228.851 319.165 223.104C329.407 210.635 334.768 194.59 334.179 178.172C334.881 161.579 329.559 145.322 319.278 132.652C314.493 126.906 308.544 122.344 301.862 119.296C295.18 116.248 287.933 114.792 280.646 115.031C273.255 114.82 265.908 116.286 259.113 119.328C252.317 122.37 246.234 126.916 241.283 132.652C230.785 145.215 225.309 161.511 225.988 178.172C225.297 194.771 230.754 211.01 241.227 223.516C246.232 229.2 252.359 233.682 259.184 236.653C266.009 239.624 273.369 241.014 280.758 240.725H280.533Z" />
              <path d="M564.341 292.648C537.125 292.648 515.232 284.817 498.662 269.154C490.353 261.141 483.824 251.326 479.53 240.399C475.236 229.471 473.283 217.695 473.807 205.896V123.667H440.067C438.276 123.941 436.448 123.776 434.729 123.183C433.009 122.59 431.446 121.587 430.163 120.253C428.88 118.918 427.913 117.29 427.338 115.497C426.764 113.703 426.597 111.795 426.853 109.922V84.0789C426.597 82.2067 426.764 80.2981 427.338 78.5048C427.913 76.7115 428.88 75.083 430.163 73.7486C431.446 72.4143 433.009 71.4109 434.729 70.8183C436.448 70.2257 438.276 70.0601 440.067 70.3348H473.807V13.8314C473.592 11.9829 473.786 10.1074 474.375 8.34972C474.964 6.59202 475.931 4.99906 477.202 3.69371C478.474 2.38836 480.015 1.4055 481.708 0.820959C483.401 0.236423 485.199 0.0658272 486.965 0.322319H524.36C526.149 0.0601745 527.972 0.23448 529.686 0.831557C531.399 1.42863 532.958 2.43226 534.238 3.76373C535.518 5.0952 536.485 6.7183 537.064 8.5058C537.643 10.2933 537.818 12.1966 537.575 14.0664V70.5698H591.502C593.289 70.3052 595.11 70.4784 596.822 71.0755C598.534 71.6727 600.09 72.6775 601.366 74.0105C602.642 75.3436 603.604 76.9684 604.176 78.7566C604.747 80.5447 604.913 82.4473 604.66 84.3139V110.51C604.913 112.376 604.747 114.279 604.176 116.067C603.604 117.855 602.642 119.48 601.366 120.813C600.09 122.146 598.534 123.151 596.822 123.748C595.11 124.345 593.289 124.518 591.502 124.254H537.575V198.554C537.241 203.532 537.937 208.527 539.616 213.199C541.294 217.871 543.916 222.111 547.303 225.631C555.34 232.538 565.596 235.983 575.981 235.264C579.055 235.264 584.285 234.97 591.67 234.383C600.443 233.502 604.829 237.613 604.829 246.776V275.615C605.073 277.36 604.97 279.139 604.528 280.841C604.086 282.543 603.314 284.132 602.259 285.509C601.204 286.886 599.89 288.021 598.397 288.846C596.905 289.67 595.266 290.164 593.582 290.299C583.925 292.014 574.136 292.801 564.341 292.648V292.648Z" />
              <path d="M652.624 285.54C650.837 285.805 649.016 285.632 647.304 285.034C645.592 284.437 644.036 283.432 642.76 282.099C641.484 280.766 640.522 279.141 639.95 277.353C639.378 275.565 639.213 273.663 639.466 271.796V14.7115C639.215 12.8428 639.382 10.9388 639.953 9.1487C640.525 7.35864 641.486 5.73123 642.76 4.39407C644.035 3.05691 645.589 2.04637 647.301 1.44166C649.012 0.836957 650.834 0.654522 652.624 0.908696H690.019C691.809 0.654522 693.631 0.836957 695.342 1.44166C697.054 2.04637 698.608 3.05691 699.882 4.39407C701.157 5.73123 702.118 7.35864 702.69 9.1487C703.261 10.9388 703.428 12.8428 703.177 14.7115V91.0674H704.021C712.283 81.8974 722.315 74.6636 733.453 69.8441C744.591 65.0245 756.581 62.7291 768.632 63.1094C780.733 62.6599 792.79 64.875 804.018 69.6108C815.247 74.3466 825.396 81.497 833.805 90.5975C842.272 100.017 848.867 111.096 853.21 123.194C857.554 135.291 859.559 148.168 859.11 161.08V271.796C859.365 273.668 859.199 275.577 858.624 277.37C858.05 279.163 857.082 280.792 855.799 282.126C854.517 283.461 852.953 284.464 851.234 285.057C849.515 285.649 847.687 285.815 845.895 285.54H808.501C806.688 285.836 804.835 285.688 803.086 285.11C801.338 284.531 799.741 283.536 798.423 282.205C797.104 280.873 796.099 279.24 795.486 277.434C794.874 275.628 794.67 273.698 794.892 271.796V170.184C795.491 156.874 791.161 143.834 782.802 133.768C778.65 129.17 773.597 125.562 767.988 123.19C762.379 120.818 756.345 119.738 750.3 120.024C744.045 119.721 737.795 120.723 731.915 122.972C726.035 125.221 720.643 128.672 716.054 133.122C711.7 137.67 708.301 143.116 706.074 149.113C703.847 155.11 702.841 161.526 703.121 167.952V271.796C703.374 273.663 703.208 275.565 702.637 277.353C702.065 279.141 701.103 280.766 699.827 282.099C698.551 283.432 696.995 284.437 695.283 285.034C693.571 285.632 691.75 285.805 689.962 285.54H652.624Z" />
              <path d="M1005.88 292.647C971.35 292.647 943.459 282.153 922.203 261.165C900.947 240.177 890.319 212.512 890.319 178.172C890.319 143.518 900.722 115.697 921.528 94.7087C942.334 73.7206 969.607 63.2266 1003.35 63.2266C1038.21 63.2266 1065.41 73.8773 1084.94 95.1786C1104.47 116.48 1114.24 146.513 1114.24 185.279C1114.49 187.145 1114.32 189.048 1113.75 190.836C1113.18 192.624 1112.22 194.249 1110.94 195.582C1109.67 196.915 1108.11 197.92 1106.4 198.517C1104.69 199.114 1102.87 199.287 1101.08 199.023H952.343C955.306 211.615 962.362 222.743 972.306 230.505C982.372 238.323 994.627 242.453 1007.17 242.252C1019.9 242.913 1032.42 238.74 1042.43 230.505C1045.91 226.821 1050.63 224.711 1055.59 224.631H1097.65C1099.21 224.482 1100.79 224.66 1102.28 225.154C1103.78 225.648 1105.17 226.449 1106.36 227.509C1107.24 228.472 1107.78 229.715 1107.89 231.038C1108 232.361 1107.68 233.684 1106.98 234.792C1098.93 253.065 1085.25 267.994 1068.13 277.199C1048.98 287.819 1027.55 293.137 1005.88 292.647V292.647ZM951.95 159.494H1053.45C1053.39 153.148 1052.12 146.879 1049.71 141.051C1047.31 135.222 1043.82 129.952 1039.45 125.545C1030.09 116.596 1017.71 111.886 1005.03 112.447C992.088 112.138 979.484 116.798 969.607 125.545C959.879 134.169 953.582 146.275 951.95 159.494V159.494Z" />
              <path d="M1164.73 285.54C1162.94 285.814 1161.12 285.649 1159.4 285.056C1157.68 284.464 1156.11 283.46 1154.83 282.126C1153.55 280.792 1152.58 279.163 1152.01 277.37C1151.43 275.576 1151.26 273.668 1151.52 271.796V83.8426C1151.26 81.9704 1151.43 80.0618 1152.01 78.2685C1152.58 76.4752 1153.55 74.8466 1154.83 73.5123C1156.11 72.1779 1157.68 71.1746 1159.4 70.5819C1161.12 69.9893 1162.94 69.8238 1164.73 70.0985H1200.84C1202.65 69.8029 1204.5 69.9503 1206.25 70.529C1208 71.1078 1209.6 72.1021 1210.91 73.434C1212.23 74.7658 1213.24 76.3989 1213.85 78.2049C1214.46 80.0109 1214.67 81.9406 1214.44 83.8426V95.5896H1215.29C1220.67 85.1967 1228.74 76.6013 1238.59 70.7927C1248.44 64.9841 1259.66 62.1985 1270.96 62.7566C1274.49 62.705 1278.01 62.8423 1281.53 63.1677C1283.22 63.2851 1284.87 63.7685 1286.37 64.5872C1287.87 65.4059 1289.19 66.542 1290.25 67.9231C1291.31 69.3042 1292.08 70.9002 1292.51 72.6095C1292.95 74.3188 1293.04 76.104 1292.78 77.8515V111.096C1292.85 113.641 1292.62 116.185 1292.1 118.673C1291.93 119.503 1291.57 120.279 1291.05 120.933C1290.53 121.587 1289.87 122.101 1289.12 122.432C1287.94 123.089 1286.63 123.47 1285.3 123.548H1279.17C1276.02 123.548 1273.54 123.548 1271.52 123.548C1263.9 123.12 1256.27 124.363 1249.13 127.195C1242 130.027 1235.51 134.387 1230.08 139.994C1219.99 150.918 1214.97 166.307 1215.01 186.159V271.796C1215.26 273.668 1215.1 275.576 1214.52 277.37C1213.95 279.163 1212.98 280.792 1211.7 282.126C1210.41 283.46 1208.85 284.464 1207.13 285.056C1205.41 285.649 1203.58 285.814 1201.79 285.54H1164.73Z" />
            </svg>
          </>
        ) : (
          <svg viewBox="0 0 528 470" xmlns="http://www.w3.org/2000/svg">
            <path d="M199.384 0.564668C79.9458 3.73638 1.16401 86.8468 0.208055 210.896C-0.410503 293.125 0.208061 375.766 0.939084 458.23C0.911232 458.778 0.911232 459.327 0.939084 459.875C1.30376 461.916 2.15591 463.828 3.4165 465.434C4.6771 467.039 6.30536 468.286 8.15036 469.06C9.99536 469.833 11.9974 470.107 13.9708 469.857C15.9443 469.606 17.8253 468.84 19.4396 467.628C58.8024 439.729 101.82 439.787 145.4 440.845C203.432 442.372 261.633 442.43 319.721 440.845C454.679 437.144 529.075 352.742 527.782 205.903C526.601 81.6194 443.433 0.97581 315.56 0.329722C276.816 0.0947804 238.072 -0.551303 199.384 0.564668ZM225.644 237.973C224.351 241.791 233.179 246.372 237.847 249.72C238.289 250.021 238.6 250.49 238.716 251.028C238.831 251.565 238.742 252.129 238.465 252.598C219.121 284.961 150.349 249.485 177.397 196.095L177.734 195.39L178.015 194.979C179.673 191.927 181.716 189.122 184.088 186.638C229.693 142.293 270.012 211.131 226.6 236.622C226.182 237.089 225.841 237.625 225.588 238.208L225.644 237.973ZM358.691 236.387C358.273 236.854 357.931 237.39 357.678 237.973V237.973C356.385 241.791 365.214 246.372 369.881 249.72C370.323 250.021 370.634 250.49 370.75 251.028C370.865 251.565 370.776 252.129 370.499 252.598C351.155 284.961 282.383 249.485 309.431 196.095L309.768 195.39V194.979C311.407 191.914 313.451 189.106 315.841 186.638C361.783 142.058 402.102 210.896 358.691 236.387Z" />
          </svg>
        )
      }
    </Box>
  );
};

const Logo = ({ noRedirect, ...props }) => {
  if (noRedirect) return <LogoSVG {...props} />;
  return (
    <Link href="/">
      <LogoSVG {...props} />
    </Link>
  );
};
export default Logo;
