import React, { memo, useState } from "react";
import { Apple, Android } from "@material-ui/icons";
import { useTranslation } from "i18n";
import {
  Button, Menu, MenuItem, ListItemIcon, ListItemAvatar, ListItemText,
} from "@material-ui/core";

const DownloadButton = ({ ...rest }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const { t } = useTranslation();
  const downloadAndroid = () => window.open("https://gorather.com/android", "_blank");
  const downloadIos = () => window.open("https://gorather.com/ios", "_blank");
  const togglePopUp = (e) => setMenuAnchor(menuAnchor ? null : e.currentTarget);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={togglePopUp}
        {...rest}
      >
        {t("DOWNLOAD_APP")}
      </Button>
         
      <Menu
        id="menu-appbar"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={togglePopUp}
      >
        <MenuItem onClick={downloadAndroid}>
          <ListItemIcon>
            <Android />
          </ListItemIcon>
          <ListItemText secondary={t("DOWNLOAD_FOR_ANDROID")} />
        </MenuItem>
        <MenuItem onClick={downloadIos}>
          <ListItemAvatar>
            <Apple />
          </ListItemAvatar>
          <ListItemText secondary={t("DOWNLOAD_FOR_IOS")} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(DownloadButton);
