export const LevelIcon = () => (
  <svg
    viewBox="0 0 32 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ffd361"
      d="M16.2406 0.583278L0.480004 10.3924L0.41598 26.1889L16.0966 36.1255L31.8572 26.3164L31.9212 10.5198L16.2406 0.583278Z"
    />
    <defs>
      <linearGradient id="level-gradient" x1="16.2406" y1="0.583278" x2="16.0966" y2="36.1255" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--lvl-gradient-1)" />
        <stop offset="0.838542" stopColor="var(--lvl-gradient-2)" />
      </linearGradient>
      <linearGradient id="level-gradient-hover" x1="16.2406" y1="0.583278" x2="16.0966" y2="36.1255" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--lvl-gradient-2)" />
        <stop offset="0.838542" stopColor="var(--lvl-gradient-1)" />
      </linearGradient>
    </defs>
  </svg>
);
