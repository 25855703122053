import { profiles, saveToken } from "api";
import { useAppState } from "store/AppState";
import { useEffect, useState } from "react";
import { useTranslation } from "i18n";

const useActiveSession = () => {
  const { state: { loading }, setState } = useAppState();
  const { setLanguage } = useTranslation();
  const [session, setSession] = useState({
    data: null,
    error: null,
  });

  const update = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      saveToken(accessToken);
      const { data, error } = await profiles.getMe();
      if (!error) {
        setLanguage(data.languageId);
        setSession({
          error: null,
          data,
        });
        setState((s) => ({
          ...s,
          loading: false,
        }));
        return;
      }
      localStorage.removeItem("accessToken");
    }
    setSession({
      error: true,
      data: null,
    });
    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  useEffect(() => {
    if (loading) update();
  }, [loading]);

  return {
    ...session,
    loading: !session.data && !session.error,
    update,
  };
};

export default useActiveSession;
