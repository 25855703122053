import DefaultSkeleton from "components/DefaultSkeleton";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSession } from "store";

const withAuthentication = (
  Component,
  conditions = {},
) => {
  const Skeleton = Component.Skeleton || DefaultSkeleton;
  const { withOnboarding = true, withRole } = conditions;
  const ProtectedRoute = (props) => {
    const { data, isLoggedIn } = useSession();
    const router = useRouter();

    useEffect(() => {
      if (isLoggedIn === false) {
        const { search, pathname } = window.location;
        router.replace({
          pathname: "/login",
          query: {
            redirect: pathname + search,
          },
        });
        return;
      }

      if (data && withRole) {
        if (data.roleId !== withRole) {
          router.replace({
            pathname: "/",
          });
        }
      }

      if (data && withOnboarding) {
        if (data.needOnboarding === true && router.pathname !== "/get-started") {
          router.replace({
            pathname: "/get-started",
            query: {
              redirect: router.pathname,
            },
          });
        }
      }
    }, [isLoggedIn]);
  
    if (isLoggedIn) return <Component {...props} />;
    return Skeleton;
  };
  return ProtectedRoute;
};

export default withAuthentication;
