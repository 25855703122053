import { useTheme } from "@material-ui/core";

export const ChallengesIcon = () => {
  const { palette } = useTheme();
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5206 18.0709C20.0581 15.9527 23.8644 12.7092 23.8644 0.92669V0H5.07471V0.92669C5.07471 12.7754 8.88098 15.9527 11.4185 18.0709C12.8685 19.3286 13.5935 19.9905 13.5935 21.4468V24.2931C12.2039 24.4255 10.8747 25.0213 10.3914 26.1466H8.45806C7.49139 26.1466 6.76638 27.0071 6.76638 28H22.1123C22.1123 26.9409 21.3269 26.1466 20.4206 26.1466H18.4873C18.0039 25.0213 16.6748 24.4917 15.2852 24.2931V21.4468C15.3456 19.9243 16.0706 19.2624 17.5206 18.0709ZM14.4998 18.6005C13.956 17.8061 13.231 17.2104 12.506 16.6146C10.2102 14.6288 7.00805 11.9811 6.8268 1.85342H22.1727C21.9915 11.9811 18.8498 14.6288 16.4935 16.6146C15.7685 17.2104 15.0435 17.8061 14.4998 18.6005Z" fill={palette.action.active} />
      <path d="M9.2438 15.8203C9.12296 15.8203 0 13.7021 0 2.78011V1.85342H5.98128V3.70684H1.75209C2.23543 12.312 9.48547 13.9669 9.54588 14.0331L9.2438 15.8203Z" fill={palette.action.active} />
      <path d="M19.7563 15.8203L19.3938 13.9669C19.6959 13.9007 26.7043 12.2459 27.1876 3.64066H22.9584V1.7872H29.0001V2.71393C29.0001 13.7021 19.8771 15.8203 19.7563 15.8203Z" fill={palette.action.active} />
    </svg>
  );
};
