import { useTheme } from "@material-ui/core";

export const Explore = () => {
  const { palette } = useTheme();
  return (
    <svg width="30" height="30" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="13" stroke={palette.action.active} strokeWidth="2" />
      <path d="M21 7L11.5 11L7 21L17 16.5L21 7Z" stroke={palette.action.active} strokeWidth="1.5" />
      <circle cx="14" cy="14" r="2" fill={palette.action.active} />
    </svg>
  );
};
