export const MedalIcon = () => (
  <svg className="m-auto mt:2" width="60" height="60" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0-362789)">
      <path d="M12.0463 31.4664L4.31543 57.9698L16.3546 55.146L24.9874 64L31.0502 43.2156L12.0463 31.4664Z" fill="#6C55F1" />
      <path d="M51.9191 31.3484L32.9458 43.2019L39.0125 64L47.6453 55.146L59.6846 57.9699L51.9191 31.3484Z" fill="#6C55F1" />
      <path d="M31.9509 44.7484C44.3078 44.7484 54.3251 34.7312 54.3251 22.3742C54.3251 10.0173 44.3078 1.52588e-05 31.9509 1.52588e-05C19.5939 1.52588e-05 9.57666 10.0173 9.57666 22.3742C9.57666 34.7312 19.5939 44.7484 31.9509 44.7484Z" fill="#FFF1B3" />
      <path d="M31.9512 1.52588e-05V44.7484C44.2883 44.7484 54.3254 34.7114 54.3254 22.3742C54.3254 10.0371 44.2883 1.52588e-05 31.9512 1.52588e-05Z" fill="#FFE898" />
      <path d="M31.951 36.1934C39.5831 36.1934 45.7702 30.0063 45.7702 22.3742C45.7702 14.7421 39.5831 8.55501 31.951 8.55501C24.3189 8.55501 18.1318 14.7421 18.1318 22.3742C18.1318 30.0063 24.3189 36.1934 31.951 36.1934Z" fill="#FFD400" />
      <path d="M31.9512 8.55501V36.1934C39.5711 36.1934 45.7703 29.9942 45.7703 22.3742C45.7703 14.7542 39.5711 8.55501 31.9512 8.55501Z" fill="#FFAF37" />
      <path d="M30.0693 18.2753H33.8337V26.4731H30.0693V18.2753Z" fill="#6C55F1" />
      <path d="M31.9512 18.2753H33.8333V26.4731H31.9512V18.2753Z" fill="#6C55F1" />
    </g>
    <defs>
      <clipPath id="clip0-362789">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
