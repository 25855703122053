import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "i18n";
import DialogForm from "./DialogForm";

const ConfirmationDialog = ({
  cancelLabel, continueLabel,
  onContinue, onCancel,
  callback, onClose,
  actions,
  ...rest
}) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation();

  const wrapAction = async (action) => {
    setLoading(true);
    if (action) await action();
    setLoading(false);
    onClose();
  };

  const defaultActions = [
    { text: cancelLabel || t("CANCEL"), onClick: () => wrapAction(onCancel) },
    { text: continueLabel || t("CONTINUE"), onClick: () => wrapAction(onContinue), variant: "contained", color: "primary" },
  ];

  return (
    <DialogForm
      cross
      open
      actions={(actions || defaultActions).map(({ text, onClick, variant, color }) => (
        <Button
          onClick={onClick}
          disabled={loading}
          variant={variant}
          color={color}
        >
          {text}
        </Button>
      ))}
      {...rest}
    />
  );
};

export default ConfirmationDialog;
