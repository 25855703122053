import React, { memo, useState } from "react";
import Logo from "components/Logo";
import {
  Container, AppBar, Toolbar, makeStyles,
  IconButton, Box, Button,
  Hidden, Avatar, Tooltip,
  Menu, MenuItem,
  Drawer, List, ListItem,
  ListItemIcon, ListItemText, Typography, useTheme,
} from "@material-ui/core";
import { Menu as MenuIcon, HomeOutlined } from "@material-ui/icons";
import { useAppState, useSession } from "store";
import Link from "next/link";
import { useTranslation } from "i18n";
import { useThemeSelector } from "theme";
import { ChallengesIcon, Explore } from "./Icons";
import SearchBar from "./SearchBar";
import ShowBanner from "./ShowBanner";
import DownloadButton from "./DownloadButton";

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: 25,
    fontWeight: 500,
    color: "white",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  drawerLogo: {
    alignSelf: "center",
  },
  expand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      width: 60,
      marginLeft: 20,
    },
    width: 170,
  },
  toolbar: {
    minHeight: 90,
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
    },
  },
  drawerPaper: {
    width: 250,
    padding: theme.spacing(2),
  },
  downloadButton: {
    marginRight: 16,
  },
}));

const Header = () => {
  const session = useSession();
  const { setState } = useAppState();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const togglePopUp = (e) => setMenuAnchor(menuAnchor ? null : e.currentTarget);
  const toggleTheme = useThemeSelector();
  const theme = useTheme();

  const signOut = () => {
    localStorage.removeItem("accessToken");
    setState({ loading: true });
  };

  return (
    <>
      <ShowBanner />
      <AppBar color="transparent" position="static">
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <Hidden smUp>
              <IconButton onClick={() => setDrawer(!drawer)}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden xsDown>
              <Logo scale={0.7} complete />
            </Hidden>
            <Box className={classes.expand}>
              <Hidden xsDown>
                <SearchBar variant="outlined" />
              </Hidden>
              <Hidden smUp>
                <Logo complete scale={0.6} />
              </Hidden>
            </Box>
            <Hidden xsDown>
              <DownloadButton className={classes.downloadButton} />
            </Hidden>
            <Hidden smDown>
              <Link href="/challenges">
                <Tooltip title={t("CHALLENGES")}>
                  <Button>
                    <ChallengesIcon />
                  </Button>
                </Tooltip>
              </Link>
            </Hidden>
            <Hidden smDown>
              <Link href="/search?query=">
                <Tooltip title={t("EXPLORE")}>
                  <Button>
                    <Explore />
                  </Button>
                </Tooltip>
              </Link>
            </Hidden>
            <Tooltip title={t("ACCOUNT")}>
              <Button
                aria-controls="menu-appbar"
                aria-haspopup="true"
                id="account"
                onClick={togglePopUp}
                color="inherit"
              >
                <Avatar src={session.data?.avatarUrl} />
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorEl={menuAnchor}
              open={!!menuAnchor}
              onClose={togglePopUp}
            >
              {
                session.isLoggedIn ? (
                  <>
                    <Link href="/profile">
                      <MenuItem>
                        {t("PROFILE")}
                      </MenuItem>
                    </Link>
                    <Link href="/profile/level">
                      <MenuItem>
                        {t("LEVEL")}
                      </MenuItem>
                    </Link>
                    <Link href="/profile/edit">
                      <MenuItem>
                        {t("EDIT_PROFILE")}
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={toggleTheme}>
                      {theme.palette.type === "light"
                        ? t("DARK_MODE")
                        : t("LIGHT_MODE")}
                    </MenuItem>
                    <MenuItem onClick={signOut}>
                      {t("SIGNOUT")}
                    </MenuItem>
                  </>
                )
                  : (
                    <>
                      <Link href="/login">
                        <MenuItem>
                          {t("LOGIN")}
                        </MenuItem>
                      </Link>
                      <Link href="/signup">
                        <MenuItem>
                          {t("SIGNUP")}
                        </MenuItem>
                      </Link>
                    </>
                  )
              }
            </Menu>
          </Toolbar>
        </Container>
        <Hidden smUp>
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            anchor="left"
            open={drawer}
            onClose={() => setDrawer(false)}
          >
            <Logo className={classes.drawerLogo} complete scale={0.8} />
            <Box m={1}>
              <SearchBar />
            </Box>
            <Box onClick={() => setDrawer(false)}>
              <List>
                <Link href="/">
                  <ListItem button>
                    <ListItemIcon>
                      <HomeOutlined color="primary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText primary={t("HOME")} />
                  </ListItem>
                </Link>
                <Link href="/search?query=">
                  <ListItem button>
                    <ListItemIcon>
                      <Explore />
                    </ListItemIcon>
                    <ListItemText primary={t("EXPLORE")} />
                  </ListItem>
                </Link>
                <Link href="/challenges">
                  <ListItem button>
                    <ListItemIcon>
                      <ChallengesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("CHALLENGES")} />
                  </ListItem>
                </Link>
              </List>
            </Box>
            <Box flexGrow={1} />
            <DownloadButton />
            <Typography variant="caption" color="textSecondary">
              {`Versión ${process.env.NEXT_PUBLIC_NPM_VERSION}`}
            </Typography>
          </Drawer>
        </Hidden>
      </AppBar>
    </>
  );
};

export default memo(Header);
