import { createContext, useContext, useMemo } from "react";
import { useActiveSession } from "hooks";

export const SessionContext = createContext({
  loading: true,
  isLoggedIn: null,
  data: null,
  error: null,
  update: async () => null,
});

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const activeSession = useActiveSession();
  const session = useMemo(() => ({
    ...activeSession,
    isLoggedIn: activeSession.loading ? null : !activeSession.error && !!activeSession.data,
  }), [activeSession]);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

export const withSession = (Component) => (props) => (
  <SessionProvider>
    <Component {...props} />
  </SessionProvider>
);
