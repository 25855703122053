import AppLayout from "layouts/AppLayout";

/* eslint-disable no-param-reassign */
const withAppLayout = (Screen) => {
  Screen.getLayout = (page) => (
    <AppLayout>
      {page}
    </AppLayout>
  );
  return Screen;
};

export default withAppLayout;
